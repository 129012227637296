import moment from "moment";
import * as htmlToImage from "html-to-image";

const DivImage = async (files: any, Logo: any, props: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (files) {
        const hasWindow = typeof window !== "undefined";
        const width: any = hasWindow ? window.innerWidth : null;
        const container = document.createElement("div");
        container.style.display = "flex";
        container.style.flexDirection = "column";
        container.style.justifyContent = "center";
        container.style.alignItems = "center";
        container.style.backgroundColor = "#fff";
        container.style.color = "#000";
        container.style.paddingBottom = "5px";
        container.style.paddingTop = "5px";
        container.style.minWidth = width < 480 ? "1024px" : "100%";
        container.style.minHeight = "100%";
        container.style.position = "relative";

        const watermark = document.createElement("img");
        watermark.src = Logo;
        watermark.alt = "watermark";
        watermark.style.position = "absolute";
        watermark.style.top = "50";
        watermark.style.left = "50%";
        watermark.style.transform = "translateX(-50%)";
        watermark.style.width = "70%";
        watermark.style.opacity = "0.3";
        watermark.style.zIndex = "1";

        const logo = document.createElement("img");
        logo.src = Logo;
        logo.alt = "logo";
        logo.style.width = "30%";
        logo.style.objectFit = "contain";

        const image = document.createElement("img");
        image.src = files;
        image.alt = "files";
        image.style.width = "100%";
        image.style.height = "auto";
        image.style.marginBottom = "5px";
        image.style.objectFit = "contain";

        const textNode = document.createTextNode(`${props?.edition} Edition`);
        const lineBreak = document.createElement("br");
        const textNode2 = document.createTextNode(
          ` Date : ${moment(props?.date).format("l")} , Page : ${
            props?.page + 1
          }`
        );

        container.appendChild(watermark); // Add watermark above the image
        container.appendChild(logo);
        container.appendChild(image);
        container.appendChild(textNode);
        container.appendChild(lineBreak);
        container.appendChild(textNode2);

        let div = document.body.appendChild(container);
        var current = await htmlToImage.toPng(div);
        const blob = dataURLtoBlob(current);
        let name = moment(new Date()).unix();
        let url = new File([blob], name + "-crop.jpg", { type: "image/jpg" });
        let file = await blobToBase64(url);

        container.style.overflow = "hidden";
        container.style.height = "0px";
        container.style.display = "none";
        resolve(file);
      } else {
        resolve("error");
      }
    } catch (err) {
      console.log("err", err);
      resolve("error");
    }
  });
};

const dataURLtoBlob = (dataURL: string) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

const blobToBase64 = async (blob: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(blob);
  });
};

export default DivImage;
